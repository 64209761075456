import React from 'react';
import { graphql, navigate } from 'gatsby';
import { FormiumForm, defaultComponents } from '@formium/react';
import { formium } from '../lib/formium';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FancyHeading from '../components/molecules/FancyHeading/FancyHeading';
import Container from '../components/atoms/Container/Container';

const TextInput = React.memo(({ type, ...props }) => {
  switch (type) {
    case 'text':
      return <input type={type} {...props} className="font-lato p-4 max-w-lg block w-full shadow-sm focus:ring-brand-red focus:border-brand-red sm:max-w-xs sm:text-sm border-gray-300" />;
    case 'email':
      return <input type={type} {...props} className="font-lato p-4 max-w-lg block w-full shadow-sm focus:ring-brand-red focus:border-brand-red sm:max-w-xs sm:text-sm border-gray-300" />;
    default:
      return <input type={type} {...props} />;
  }
});

const SubmitButton = React.memo(({ type, ...props }) => <button type={type} {...props} className="mb-4 mr-4 inline-flex items-center text-xs font-lato font-bold leading-3 px-12 py-4 border-2 border-black uppercase bg-transparent transition transition-duration-500 hover:bg-brand-red hover:border-brand-red hover:text-white" />);

const Header = React.memo(({ ...props }) => <h2 className="font-lato text-2xl mb-8">{props.page.title}</h2>);

const FormControl = React.memo(({
  children,
  description,
  error,
  label,
  labelFor,
}) => (
  <div className="mb-4">
    <div className="mb-4">
      {label && <label htmlFor={labelFor} className="text-xl font-lato">{label}</label>}
      {description && <div className="font-lato">{description}</div>}
    </div>
    <div className="mb-4">
      {children}
    </div>
    {error && <div className="text-brand-red">{error}</div>}
  </div>
));

// Always define these outside of React so that
// components are not needlessly recreated on each render
const myComponents = {
  ...defaultComponents,
  TextInput,
  FormControl,
  SubmitButton,
  Header,
};

export default function FeedbackForm({ data }) {
  return (
    <>
    <SEO title="Download" />
      <Layout>
        <Container className="bg-brand-light-grey">
          <FancyHeading headingSize="1">Download Discussion Guide</FancyHeading>
          <FormiumForm
            data={data.formiumForm}
            components={myComponents}
            onSubmit={async (values) => {
              await formium.submitForm("aig-book-launch-download-form", values);
              navigate("/guide-thank-you");
            }}
          />
        </Container>
      </Layout>
    </>
  );
}

export async function getStaticProps(context) {
  const form = await formium.getFormBySlug("aig-book-launch-download-form");
  return { props: { form } };
}

export const query = graphql`
  {
    formiumForm(slug: { eq: "aig-book-launch-download-form" }) {
      id
      createAt
      name
      projectId
      schema
      slug
      updateAt
      version
    }
  }
`;
